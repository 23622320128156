const Pages = ()=>{
  return([
    {
      "PageName":"Home",
      "Name":"Home",
      "Paths":  ["/","/home"],
      "Menu": ["SiteMap", "Header", "Mobile"]
    },
    {
      "PageName":"Get Started",
      "Name":"GetStarted",
      "Paths": ["/getstarted"],
      "Menu": ["SiteMap", "Header", "Mobile", "Footer"]
    },
  
    {
      "PageName":"Reset Password",
      "Name":"ResetPassword",
      "Paths": ["/resetpassword/:token", "/resetpassword"],
      "Menu": []//["SiteMap", "Header", "Mobile"]
    },
  
    {
      "PageName":"Best Aligners",
      "Name":"BestAligners",
      "Paths": ["/bestaligners"],
      "Menu": ["SiteMap", "Header", "Mobile"]
    },
    
  
  
  
    {
      "PageName":"Pricing",
      "Name":"Pricing",
      "Paths": ["/pricing"],
      "Menu": ["SiteMap", "Header", "Mobile"]
    },
  
  
  
    // {
    //   "PageName":"Testimonials",
    //   "Name":"Testimonials",
    //   "Paths": ["/testimonials"],
    //   "Menu": ["None", "Header", "Mobile"]
    // },
  
  
  
  
    {
      "PageName":"Store",
      "Name":"Store",
      "Paths": ["/store"],
      "Menu":  [""]
    },
  
  
  
    
  
  
  
  
    
  
  
  
    {
      "PageName":"EmailConfirm",
      "Name":"EmailConfirm",
      "Paths": ["/emailConfirm"],
      "Menu":  [""]
    },
  
  
  
    {
      "PageName":"EmailUnsubscribe",
      "Name":"EmailUnsubscribe",
      "Paths": ["/emailUnsubscribe"],
      "Menu":  [""]
    },
    {
      "PageName":"RedirectPage",
      "Name":"RedirectPage",
      "Paths": ["/rd"],
      "Menu":  [""]
    },
    // {
    //   "PageName":"SimpleSignup",
    //   "Name":"SimpleSignup",
    //   "Paths": ["/SimpleSignup"],
    //   "Menu":  [""]
    // },
    {
      "PageName":"Checkout",
      "Name":"Checkout",
      "Paths": ["/checkout"],
      "Menu":  [""]
    },
    
    {
      "PageName":"Impression Kit",
      "Name": "ImpressionKit",
      "Paths": ["/impressionkit"],
      "Menu": ["Footer1"]
    },
  
  
  
  
    {
      "PageName":"Faq",
      "Name":"Faq",
      "Paths": ["/faq"],
      "Menu": ["SiteMap", "Footer2"]
    },
  
    {
      "PageName":"Blog",
      "Name":"Blog",
      "Paths": ["/blog"],
      "Menu":  ["SiteMap", "Footer1", "Mobile"]
    },

    {
      "PageName": "About",
      "Name": "About",
      "Paths": ["/about", "/aboutus"],
      "Menu": ["SiteMap", "Footer2"]
    },
  
  
  
    {
      "PageName":"Contact",
      "Name":"Contact",
      "Paths": ["/contact"],
      "Menu": ["SiteMap", "Footer1", "Mobile"]
    },
  
    {
      "PageName":"Consent",
      "Name":"Consent",
      "Paths": ["/consent"],
      "Menu": ["SiteMap", "Footer2"]
    },
  
  
  
    {
      "PageName":"Privacy",
      "Name":"Privacy",
      "Paths": ["/privacy"],
      "Menu": ["SiteMap", "Footer2"]
    },
  
  
  
    {
      "PageName":"Terms of Use",
      "Name":"Terms",
      "Paths": ["/termsofuse"],
      "Menu": ["SiteMap", "Footer2"]
    },
  
  
    {
      "PageName":"Guarantees",
      "Name":"Guarantees",
      "Paths": ["/guarantees"],
      "Menu": ["SiteMap", "Footer2"]
    },
  
    {
      "PageName":"Giveaway",
      "Name": "ClearAligners",
      "Paths": ["/giveaways"],
      "Menu":  [""] //["SiteMap", "Footer1", "Header", "Mobile"]
    },
  
    {
      "PageName":"Promo",
      "Name":"Promo",
      "Paths": ["/promo"],
      "Menu":  ["SiteMap", "Header", "Footer1", "Mobile"]
    },
    {
      "PageName":"BuyKit",
      "Name":"BuyKit",
      "Paths": ["/buykit"],
      "Menu":  [""]
    },
    {
      "PageName":"Buy Now",
      "Name":"BuyNow",
      "Paths": ["/buy"],
      "Menu":  ["Footer1", "Mobile"]
    },
    {
      "PageName":"Page404",
      "Name":"Page404",
      "Paths": ["/404"],
      "Menu": [""]
    },
    // {
    //   "PageName":"SubmitBeforeAndAfter",
    //   "Name":"SubmitBeforeAndAfter",
    //   "Paths": ["/submit-before-and-after"],
    //   "Menu": ["Footer2"]
    // },
    {
      "PageName":"CustomLandingPage1",
      "Name":"CustomLandingPage1",
      "Paths": ["/deals001"],
      "Menu": [""]
    },

    {
      "PageName":"CustomLandingPage2",
      "Name":"CustomLandingPage2",
      "Paths": ["/deals002"],
      "Menu": [""]
    },
    {
      "PageName":"CustomLandingPage3",
      "Name":"CustomLandingPage3",
      "Paths": ["/deals003"],
      "Menu": [""]
    },
    
    // {
    //   "PageName": "Winners",
    //   "Name": "Winners",
    //   "Paths": ["/winners"],
    //   "Menu":  ["SiteMap", "Footer1", "Mobile"]
    // }
    {
      "PageName":"Login",
      "Name":"Login",
      "Paths": ["/login"],
      "Menu": ["Mobile", "Footer1"]
    }
  ])
}


export default Pages;