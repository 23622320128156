import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import {SERVER_RENDER_DEFAULT_SITE_INFO}  from 'App';
import 'styles/scss/website/pages/blog.scss';
import {DangerSet} from 'global/utils/helper-functions';
import {Helmet} from "react-helmet-async";
import {NavLink, Link, useParams} from 'react-router-dom';
import API_CALLS from 'API_CALLS/index';
import CONTENTS from "./CONTENTS";
import Loading from 'global/components/Loading/index';
import CategoryList from '../components/CategoryList/index';
import RecentPosts from '../components/RecentPosts/index';
//import KeywordsList from '../components/KeywordsList/index';
import TreatmentPlanBar from 'website/components/TreatmentPlanBar/index';
import LayoutBuilder from 'LayoutBuilder';
import PAGES from 'website/pages/pages_FULL.js'; //ADD NEW PAGES HERE


const {Container, Row, Column} = LayoutBuilder;
const QUERY = API_CALLS.BLOGS.QUERIES.blogPage();




class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false// true
    };

  }
  
  componentDidMount(){
    var Session = (typeof window === 'undefined') ? SERVER_RENDER_DEFAULT_SITE_INFO : window.Session;
    const PAGES_LIST = PAGES(Session.Language);
    var pageInfo = PAGES_LIST.filter(obj=>obj.Paths[0] ===`/blog/${this.props.params.blogId}`)
    if(!pageInfo){
      pageInfo = this.props.pageInfo;
      pageInfo.Paths = [`/blog/${this.props.params.blogId}`];
    }
    window.GlobalUtil.triggerEvent("PageChangedEvent", pageInfo);
    // dispatchEvent(new Event('load'));
    // document.querySelector("#Content").classList.toggle("allReady")
  }

  componentWillUnmount(){
  }

  onFailed = () => {
    this.setState({curStatus: "failed", loading: false});
  }

  
  render(){
    var {loading} = this.state;
    var {blog={}, CONTENT} = this.props;
    var Session = (typeof window === 'undefined') ? SERVER_RENDER_DEFAULT_SITE_INFO : window.Session;
    var HEADER = CONTENTS[Session.Language].HEADER; //HOPEFULY WE'LL BE ABLE TO PULL ALL THIS INFO FROM THE BLOG POST ITSELF SO EACH ONE CAN HAVE GREAT SEO
    if(blog && blog.SEO) HEADER = {...HEADER, ...blog.SEO};
    //console.log('blog', blog);
    // console.log('this.props', this.props);
      
    
    return (
      <div className="contentPage" id="PageBlogSingle">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{HEADER.TITLE}</title>
            <meta name="description" content={HEADER.DESCRIPTION}/>
            <link  href={`${window.location.pathname}`} />
            <link rel="canonical" href={`${HEADER.BASEURL}${window.location.pathname}`} />
            <meta name="keywords" content={HEADER.KEYWORDS} />

            {"<!-- Google / Search Engine Tags -->"}
            <meta itemprop="name" content={HEADER.TITLE} />
            <meta itemprop="description" content={HEADER.DESCRIPTION}/>
            <meta itemprop="image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Facebook Meta Tags -->*/}
            <meta property="og:url" content={`${HEADER.BASEURL}${window.location.pathname}`} />
            <meta property="og:type" content="website" />
            <meta property="og:title" content={HEADER.TITLE} />
            <meta property="og:description" content={HEADER.DESCRIPTION}/>
            <meta property="og:image" content="https://Smiles.Club/SocialImage_500.jpg" />

            {/*<!-- Twitter Meta Tags -->*/}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={HEADER.TITLE} />
            <meta name="twitter:description" content={HEADER.DESCRIPTION}/>
            <meta name="twitter:image" content="https://Smiles.Club/SocialImage_500.jpg" />
        </Helmet>
        <Container className="">
          <Row className="">
            <Column className="" col="" xs="" sm="" md="" lg="" xl="">
              <div className="breadcrumbsWrapper">
                <div className={`breadcrumbs`}>
                  <Link className="blogLink"  to={((Session.Language === "ENGLISH") ? "/" : "/inicio")}><i className="fas fa-home"></i></Link>
                  <div className="dividerIcon"><i className="fas fa-chevron-right"></i></div>
                  <Link className="blogLink"  to={((Session.Language === "ENGLISH") ? "/blog" : "/elBlog")}>{CONTENT.BreadCrumbs[1]}</Link>
                  <div className="dividerIcon"><i className="fas fa-chevron-right"></i></div>
                  <div className="lastLink">{window.GlobalUtil.deepGetFromString(this, 'props,match,params,blogId', undefined)}</div>
                </div>
              </div>
            </Column>
          </Row>
          <Row className="">
            {
              //(window.innerWidth < 992) &&
              <MainSection className="" CONTENT={CONTENT} blog={blog}/>
            }
            <Column className="menuSection" col="12" xs="12" sm="12" md="12" lg="3" xl="3">
              <CategoryList withNav={true} />
              <RecentPosts /> 
              {/* <KeywordsList/> */}
            </Column>
          </Row>
        </Container>
        <TreatmentPlanBar/>
      </div>
    );
  }
}






function MainSection(props) {     
  var {className, CONTENT, blog} = props;   
  return (
    <Column className={`mainSection ${className}`} col="" xs="" sm="" md="" lg="" xl="">
      {
        (blog && blog._id)
        ? <React.Fragment>
            <div className="">
              <div className="mainImage"><img src={window.GlobalUtil.deepGetFromString(blog, 'mainImage,0,url', undefined)} alt=""/></div>
              <br/>
              <h1 className="sectionTitle">{blog.title}</h1>
              <div className="blogPostMeta">
                <div className="blogPostMetaDateCategory">{blog.category ? blog.category : ""} - {window.GlobalUtil.dateBasic(blog.duration.startAt)}</div>
                <div className="blogPostMetaKeywords">{CONTENT.BlogZone[2]}: {blog.keywords && `${blog.keywords.slice(0,5).join(", ")}`}</div>
              </div>
            </div>
            <div className="">{
                DangerSet({
                "newValue": blog.content,
                "className": "blogContent",
                "id": "",
                "type": "div",
              })
            }</div>
          </React.Fragment>
        : <div className="pageNotFound">
            <h2 className="">Page not found</h2>
            <div className="">Sorry the blog post you are looking for cannot be found. Please return to our blog page.</div>
            <div className=""><Link className="button button1"  to="/blog">Return to Blog</Link></div>
          </div>
      }
    </Column>
  );
}









function ParamsWrapper(props) {     
  let params = useParams();
  var Session = (typeof window === 'undefined') ? SERVER_RENDER_DEFAULT_SITE_INFO : window.Session;
  const CONTENT = window.GlobalUtil.dangerSetGlobalObjList(CONTENTS[Session.Language].CONTENT); 
  const { loading, error, data, refetch } = useQuery(QUERY, {
    variables: {"url":params.blogId},
    //fetchPolicy: "no-cache"
  });
  if (loading) return <Loading 
    //floating="true"
    header={CONTENT.Loading[0]}
    body={CONTENT.Loading[1]}
    inlineStyles={{
      "LoadingScreen": {
        "paddingTop": "100px"
      }
    }}
  />;
  if (error) return `Error! ${error.message}`;
  //console.log(`data`,"\n\n",data,"\n\n");           
  return (
    <Index
      {...props}
      params={params}
      CONTENT={CONTENT}
      blog={(typeof window === 'undefined') ? data.blogPage : window.GlobalUtil.stripOutFromObj(data.blogPage, "__typename")}
    />
  );
}



// function ParamsWrapper(props) {
//   // Get the userId param from the URL.
//   let params = useParams();
//   return(
//     <Index {...props} params={params} />
//   )
// }


export default ParamsWrapper;
      